import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import he from 'he'

import BrandBanner from '../components/brand-banner'
import SplitScreen from '../components/split-screen'
import Bar from '../components/archive/bar'
import Video from '../components/archive/video'
import Tile from '../components/archive/tile'
import TextItem from '../components/archive/text-item'

class CategoryPage extends Component {
  constructor(props) {
    super(props)

    const category = this.props.data.wordpressPage

    this.state = {
      lhs: category.acf.main_image[Math.floor(Math.random() * category.acf.main_image.length)]
    }
  }

  render() {
    const category = this.props.data.wordpressPage
    const { lhs } = this.state

    const splitScreen = {
      homepage: false,
      lhs: lhs.image && lhs.image.url,
      rhs: category.acf.rhs_articles,
      categories: this.props.data.allWordpressCategory.edges,
      top_articles: category.acf.top_articles || this.props.data.top_articles_fallback.acf.top_articles
    }

    return (
      <>
        <Helmet>
          <body className={`page--category ${lhs.image_is_dark && 'header--lhs-dark'}`} />
          <title>{ he.decode(category.yoast_meta.yoast_wpseo_title) }</title>
          <meta name="description" content={category.yoast_meta.yoast_wpseo_metadesc} />
          <meta property="og:title" content={ category.yoast_meta.yoast_wpseo_title } />
          <meta property="og:description" content={ category.yoast_meta.yoast_wpseo_metadesc } />
          <meta property="twitter:title" content={ category.yoast_meta.yoast_wpseo_title } />
          <meta property="twitter:description" content={ category.yoast_meta.yoast_wpseo_metadesc } />
        </Helmet>
        <SplitScreen dark={true} {...splitScreen} key={splitScreen.lhs} />
        { category.acf.fold_article && <Bar {...category.acf.fold_article} /> }
        { category.acf.video_section && <Video {...category.acf.video_section} /> }
        <div className='archive__collection'>
          { category.acf.tile_articles && category.acf.tile_articles.map((el, i) => {
            return <Tile {...el} key={i} />
          }) }
        </div>
        <div className='archive__text-item__container'>
          <div className='archive__text-item__inner'>
            { category.acf.title_articles && category.acf.title_articles.map((el, i) => {
              return <TextItem {...el} key={i} />
            }) }
          </div>
        </div>
        <BrandBanner />
      </>
    )
  }
}

export default CategoryPage

export const categoryQuery = graphql`
  query ($name: String!) {

    allWordpressCategory {
      edges {
        node {
          name
          slug
        }
      }
    }

    wordpressPage(title: {eq: $name}) {
      title
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        main_image {
          image {
            url
            alt
          }
          image_is_dark
        }
        rhs_articles {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            featured_image {
              url
              alt
            }
            content_blocks {
              content
            }
          }
        }
        fold_article {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            featured_image {
              url
              alt
            }
          }
        }
        video_section {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            video
            featured_image {
              url
              alt
            }
          }
        }
        tile_articles {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            featured_image {
              url
              alt
            }
            content_blocks {
              content
            }
          }
        }
        title_articles {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            featured_image {
              url
              alt
            }
          }
        }
        top_articles {
          post_title
          post_name
        }
      }
    }

    top_articles_fallback: wordpressPage(title: {eq: "Home"}) {
      acf {
        top_articles {
          post_title
          post_name
        }
      }
    }

  }
`
